// Seção
.home-sobre {
  //background: $cor_fundo2;
}

// Esquerda
.home-sobre-left {
  padding-right: 10px;

  // MOBILE
  @include mq(s) {
    padding-right: 0px;
  }

  // Texto
  .texto {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }

    b,
    strong {
      color: $cor_primaria;
    }
  }

  // Botão
  .btn-container {

    // MOBILE
    @include mq(s) {
      text-align: center !important;
    }
  }
}

// Direita (Foto)
.home-sobre-right {
  overflow: hidden;
  border-radius: 5px;

  // MOBILE
  @include mq(s) {
    width: 100%;
    margin: 0px;
    margin-bottom: 15px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 28px;
    right: 35px;
    bottom: 28px;
    left: 35px;
    border: 1px solid rgba(#FFF, .3);
    border-radius: 5px;

    // MOBILE
    @include mq(s) {
      display: none;
    }
  }

  img {
    transition: .5s ease-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
}
