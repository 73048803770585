// Galeria caixa
.caixa-galeria {
  margin-bottom: 20px;

  // Foto
  figure {
    float: left;
    margin-bottom: 10px;
    overflow: hidden;

    img {
      transition: .3s ease-out;
    }
  }

  &:hover {
    figure img {
      transform: scale(1.1);
    }
  }

  // Título
  h2 {
    float: left;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    color: $cor_fonte_escura;
    font-size: 18px;
    transition: .3s ease-out;

    // MOBILE
    @include mq(s) {
      font-size: 15px;
      height: 40px;
    }

    // TABLET
    @include mq(m) {
      height: 40px;
    }
  }

  &:hover {
    h2 {
      color: $cor_primaria;
    }
  }
}

// Lista de fotos detalhe
.galeria-lista {
  float: left;
  width: 100%;
}

.segura-bloco {
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 18px;
  //background: #F1F3F4;

  // Galeria caixa detalhe
  .galeria-thumb {
    display: inline-block;
    overflow: hidden;
    border: 0px solid $cor_secundaria;
    border-radius: 5px;

    figure {
      overflow: hidden;

      img {
        transition: .3s ease-out;
      }
    }

    &:hover figure img {
      transform: scale(1.1);
    }
  }

  .segura-info {

    h4 {
      //text-align: center;
      color: $cor_primaria;
      font-weight: 400;
      font-size: 16px;
      margin-top: 3px;
      //padding-bottom: 7px;

      // MOBILE
      @include mq(s) {
        font-size: 14px;
      }

    }
  }

}
