// Foto
.servico-detalhe-foto {
  margin-bottom: 10px;

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
  }
}

// Texto
.servico-detalhe-texto {

  // BOTÃO
  .btn-container {
    float: none;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }
}

.segura-servico-foto {
  margin-top: 20px;
}
