// Header principal
.header {
  position: fixed;
  z-index: $z_index_header;
  float: left;
  width: 100%;
  top: 0;
  background: #FFF;
  padding-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .26);

  // TABLET
  @include mq(m) {
    padding-top: 10px;
    padding-bottom: 14px;
  }

  // MOBILE
  @include mq(s) {
    padding-top: 36px;
    padding-bottom: 12px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;

  }
}

// Conteúdo (Container)
.header .container {
  position: static;
}

.header-content {
  padding: 0 10px;
}

// Logo
.header-logo {
  display: block;
  float: left;
  max-width: 260px;

  // TABLET
  @include mq(m) {
    max-width: 230px;
    margin-top: 6px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 190px;
    //margin-left: 30px;
  }
}

// Direita
.header-direita {
  float: right;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Barra
.header-barra {
  height: 35px;
  margin-bottom: 7px;
  background: $cor_fundo2;
  overflow: hidden;

  // TABLET
  @include mq(m) {
    display: none;
  }

  // MOBILE
  @include mq(s) {
    display: none;
  }
}

// Frase
.header-barra-frase {
  float: left;
  padding-left: 10px;
  padding-top: 7px;
  color: $cor_fonte_escura;
  font-size: 14px;

  b {
    color: $cor_primaria;
    font-weight: 600;
  }
}

// Telefones
.header-barra-telefones {
  position: relative;
  float: right;
  padding: 0 10px;
  //padding-top: 7px;
  color: #FFF;
  font-size: 15px;
  height: 35px;
  background: $cor_primaria;

  .segura-telefones {
    padding-top: 7px;
  }

  .btn {
    border-radius: 0px;
  }

  .btn-sm {
    padding: 12px 20px;
    font-size: 13px;
  }

  // Triângulo
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -70px;
    width: 0px;
    height: 0px;
    border-right: 35px solid $cor_primaria;
    border-bottom: 35px solid transparent;
    border-left: 35px solid transparent;
  }

  // Barra
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1000px;
    width: 1000px;
    height: 35px;
    background: $cor_primaria;
  }

  // Unidade
  >div {
    float: right;
    margin-left: 20px;

    &:last-child {
      margin-left: 0px;
    }
  }

  // Nome unidade
  span {
    display: inline-block;
    font-weight: 600;
    margin-right: 5px;

    i {
      color: #FFF;
      margin-right: 5px;
    }
  }

  // Telfone
  a {
    display: inline-block;
    color: #FFF;
    font-weight: normal;
    transition: .2s ease-out;

    &:hover {
      color: #FFF;
    }
  }
}

// Telefones
.header-telefones {
  float: left;
  text-align: left;
  display: none;

  // TABLET
  @include mq(m) {
    display: block;
    float: right;
    text-align: right;
  }

  // MOBILE
  @include mq(s) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    background: $cor_fundo2;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  // Item
  a {
    display: inline-block;
    margin-left: 20px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: $cor_fonte_escura;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 14px;
      margin: 0 5px;
    }

    i {
      margin-right: 5px;
      color: $cor_primaria;
    }
  }
}
