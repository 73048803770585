.chamada-home {

  .texto {
    color: #FFF;
    font-size: 20px;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
  }

  .btn-container {
    margin-top: 30px;
  }
}
